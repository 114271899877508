import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

export default function AboutPage () {
  const data = useStaticQuery(graphql`
    query about {
      bannerImage: file(relativePath: { eq: "construction-workers-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      site {
        siteMetadata {
          siteTitle
          siteDescription
          author
          siteUrl
          siteImage
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title='About' data={data.site} />
      <Img fluid={data.bannerImage.childImageSharp.fluid} style={{ maxHeight: '450px' }} alt='about banner image' />
      <div className='container'>
        <section className='my-4 container'>
          <h1 className='text-center'>About</h1>
          <p className='lead'>
            In the fall of 2021, a team of researchers led by Professor Bob Bruno, Director of the Project for Middle Class Renewal at the University of Illinois, conducted a survey of adults working for pay and not on active military duty in Illinois. The survey asked about a variety of job characteristics that relate to employment quality, including the amount and type of pay, benefits such as health insurance and paid leave, workplace safety and mistreatment, work hours and scheduling, the degree of freedom and meaning workers experience at work, and chances for continued employment or promotion. The survey also asked workers to rate their happiness at work, job satisfaction, and overall employment situation. These data provide a detailed picture of how workers across Illinois—and potentially elsewhere in the country—evaluate their jobs.
          </p>
          
          <p className='lead'>
            The survey was administered as an online questionnaire using the Qualtrics platform. Respondents were recruited anonymously from a large panel that Qualtrics aggregates from over 20 providers of actively managed, convenience samples. Informed consent and screening questions were required at the beginning of the survey. Quotas were set for region, race, gender, industry, and self-employment to avoid extreme over-representation of those more likely to respond. No additional incentive was provided beyond what Qualtrics offers panelists for their participation. The median survey duration was 13 minutes.
          </p>
          
          <p className='lead'>
            Researchers used multiple techniques to validate responses and ensure data quality. All text responses were manually coded or reviewed using <a href='https://discovertext.com/' target='__blank'>DiscoverText</a>. Nonsensical responses or surveys completed in less than half the median duration were flagged for a full review. Surveys with incoherent responses were excluded from the analytic sample. There was some duplication of IP addresses and text responses, as is common in anonymous online surveys. For cases with identical IP addresses, age, gender, race and ethnicity, only the first complete case was used. Numeric responses (e.g. wages) were truncated at the 1st and 99th percentiles of the sample distribution to reduce the influence of outliers.
          </p>
          
          <p className='lead'>
            The cleaned data include 3,539 surveys, of which two thirds (68%) have complete information on key demographic and job characteristics. Survey weights were calibrated to match the composition of the Illinois workforce by age, race/ethnicity, education, region, and sex in the American Community Survey 2015-2019 sample (accessed via the IPUMS USA portal). Even with these weights, the analytic sample includes a disproportionate share of lower-income households, multiple job holders, and workers with non-standard employment arrangements (part-time, temporary, independent contractors, etc.). This may bias estimates of job characteristics or attitudes that differ between workers in standard and non-standard arrangements. Results are reported separately by job type where relevant.
          </p>
          
          <p className='lead'>
            Researchers developed a statistical model to assess the relative importance of more than 20 job characteristics in predicting self-rated employment quality. This model explains 40 percent of the variation in employment quality and 57 percent of the variation in job satisfaction. A technique known as Shapley regression was used to compare the explanatory power of different sets of predictors, grouped according to the dimension of employment quality they are supposed to measure. The dashboard on the home page displays the predicted employment quality score (calculated as 10 times the 0-10 scale used in the survey) for a typical worker in Illinois. The predicted score changes depending on the values of a subset of predictors, which were selected for their explanatory importance or relation to theoretically distinct dimensions of employment quality.
          </p>
        </section>
        
        <section className='my-4 container lead'>
          All icons by <a target="_blank" href="https://icons8.com">Icons8</a>.
        </section>
      </div>
    </Layout>
  )
}